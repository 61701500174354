import React, { useContext, useEffect } from 'react';
import * as S from './CategoryContainerV1.styles';
import { Builder } from '@builder.io/react';
import FunnelContext from '../../context/FunnelContext';
import useLocalStorage from '../../hooks/useLocalStorage';
import { CSSTransition } from 'react-transition-group';

const CategoryContainer = props => {
  const { category, checkLocalCart, children, addTransition } = props;
  const { currentCategory, setCurrentCategory } = useContext(FunnelContext);
  const [localCart] = useLocalStorage('local-cart');

  useEffect(() => {
    if (checkLocalCart) {
      setCurrentCategory(localCart?.extraData?.currentCategory);
    }
  }, [checkLocalCart, setCurrentCategory, localCart]);

  const noChildren = () => {
    return <S.NoChildren>insert components here</S.NoChildren>;
  };

  const duration = 1000,
    onEnter = node => {
      node.style.marginTop = `-${node.offsetHeight}px`;
      node.style.marginBottom = `0px`;
    },
    onEntering = node => {
      node.style.marginTop = '';
      node.style.marginBottom = '';
    },
    onExit = node => {
      node.style.marginTop = '';
      node.style.marginBottom = '';
    },
    onExiting = node => {
      node.style.marginTop = `-${node.offsetHeight}px`;
      node.style.marginBottom = `0px`;
    };

  if (!addTransition && currentCategory !== category && !Builder.isEditing) {
    return <></>;
  }

  return addTransition ? (
    <CSSTransition
      in={currentCategory === category}
      timeout={duration}
      classNames="slide"
      unmountOnExit
      onEnter={onEnter}
      onEntering={onEntering}
      onExit={onExit}
      onExiting={onExiting}
    >
      <S.Container category={category} editing={Builder.isEditing}>
        {children || noChildren()}
      </S.Container>
    </CSSTransition>
  ) : (
    <S.Container category={category} editing={Builder.isEditing}>
      {children || noChildren()}
    </S.Container>
  );
};

export default CategoryContainer;
